import { Loader } from 'klaviyo-loader';
import getConfig from 'next/config';

export const getKlaviyoToken = (): string => {
  const { publicRuntimeConfig } = getConfig();
  return publicRuntimeConfig.KLAVIYO_PUBLIC_KEY;
};

export const getLoader = (): Loader => {
  const token = getKlaviyoToken();
  return new Loader(token);
};
