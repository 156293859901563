import Api from 'api/Api';
import { Header, Loading, Spinner, Toast } from 'components/';
import useAuth from 'hooks/useAuth';
import Image from 'next/image';
import { useRouter, withRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { getLoader } from '../klaviyo/Klaviyo';
interface ErrorType {
  message: string | string[];
  campos: string[];
}
const Login = () => {
  const [username, setUsername] = useState<string>();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState<string | string[]>();
  const [returnUrl, setReturnUrl] = useState<string>();
  const [isRecoveringPassword, setIsRecoveringPassword] = useState(false);
  const [error, setError] = useState<ErrorType | undefined>();
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const { login, user } = useAuth();
  useEffect(() => {
    const loadLogin = () => {
      if (user?.id) {
        return router.push('/admin/inmuebles');
      } else {
        if (router.query.message) {
          setMessage(router.query.message);
          delete router.query.message;
        }
        if (router.query.email) setUsername(router.query.email.toString());
        if (router.query.returnUrl) setReturnUrl(router.query.returnUrl.toString());
        setLoading(false);
      }
    };
    loadLogin();
  }, [router, user]);

  const handlePasswordRecovering = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const origin = typeof window !== 'undefined' && window.location.origin ? window.location.origin : '';
      const res = await Api().post('users/recover-password', { email, back_url: origin });
      if (res.status == 200 || res.status == 201) {
        setError(undefined);
        setMessage(res.data.message);
        setIsRecoveringPassword(true);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error.response);
      setError(error.response.data);
      setMessage(error.response.data.message);
      setLoading(false);
    }
  };
  const klaviyoIdentify = (email) => {
    const loader = getLoader();
    loader.load().then((klaviyo) => {
      klaviyo.push([
        'identify',
        {
          $email: email,
        },
      ]);
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await login({ username, password });
      klaviyoIdentify(username);
      return router.replace(returnUrl ? returnUrl.toString() : '/admin/inmuebles');
    } catch (error) {
      if (error.response) {
        // console.log(error.response);
        setError(error.response.data.data);
        setMessage(error.response.data.data.message);
      }
      setLoading(false);
    }
  };
  if (loading) return <Loading />;

  return (
    <>
      <Header variant='transparent' shouldDisplayGoToPortal />
      {message && (
        <div className='absolute w-full top-14'>
          <Toast variant={error?.message ? 'error' : 'success'} msg={message} onHide={() => setMessage('')} timeout={3500} />
        </div>
      )}
      <div className='flex items-center justify-center w-screen h-screen bg-no-repeat bg-cover'>
        <Image src='/images/bg.jpg' alt='home page background' layout='fill' className=' -z-1' />
        <div className='z-10 flex flex-col px-12 py-8 mt-4 bg-white border rounded-3xl border-primary bg-opacity-80'>
          <div className='flex items-center self-center w-32 h-32 -mt-24 rounded-full bg-primary'>
            <img src='/images/LOGOSINFONDO blanco copy.png' alt='Imagen logotipo de la empresa' className='self-center' />
          </div>
          <h2 className='px-3 m-auto my-3 text-4xl text-center border-b border-primary text-primary'>¡Bienvenido!</h2>
          {!isRecoveringPassword ? (
            <form onSubmit={handleLogin} className='w-72' onChange={() => setError(undefined)}>
              <div
                className={[
                  'relative flex w-full py-2 my-3 bg-white border rounded-xl ',
                  error?.campos?.includes('username') ? 'ring-1 ring-red-500 border-red-500' : 'border-primary',
                ].join(' ')}
              >
                <label htmlFor='username' className='self-center inline w-5 h-5 mx-2 text-primary'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.11 13.23'>
                    <g id='Capa_2' data-name='Capa 2'>
                      <g id='Layer_1' data-name='Layer 1'>
                        <path fill='currentColor' d='M5.56,0A2.91,2.91,0,1,0,8.47,2.91,2.91,2.91,0,0,0,5.56,0Z' />
                        <path
                          fill='currentColor'
                          d='M5.56,6.35A5.55,5.55,0,0,0,0,11.91V13a.26.26,0,0,0,.26.26H10.85a.26.26,0,0,0,.26-.26V11.91A5.55,5.55,0,0,0,5.56,6.35Z'
                        />
                      </g>
                    </g>
                  </svg>
                </label>
                <input
                  id='username'
                  className='w-full outline-none'
                  type='text'
                  name='username'
                  placeholder='Usuario'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div
                className={[
                  'relative flex w-full py-2 my-3 bg-white border rounded-xl ',
                  error?.campos?.includes('password') ? 'ring-1 ring-red-500 border-red-500' : 'border-primary',
                ].join(' ')}
              >
                <label htmlFor='password' className='self-center inline w-5 h-5 mx-2 text-primary'>
                  <svg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      fill='currentColor'
                      d='m18.75 9h-.75v-3c0-3.309-2.691-6-6-6s-6 2.691-6 6v3h-.75c-1.24 0-2.25 1.009-2.25 2.25v10.5c0 1.241 1.01 2.25 2.25 2.25h13.5c1.24 0 2.25-1.009 2.25-2.25v-10.5c0-1.241-1.01-2.25-2.25-2.25zm-10.75-3c0-2.206 1.794-4 4-4s4 1.794 4 4v3h-8zm5 10.722v2.278c0 .552-.447 1-1 1s-1-.448-1-1v-2.278c-.595-.347-1-.985-1-1.722 0-1.103.897-2 2-2s2 .897 2 2c0 .737-.405 1.375-1 1.722z'
                    />
                  </svg>
                </label>
                <input
                  id='password'
                  className={['w-full outline-none'].join(' ')}
                  type='password'
                  placeholder='Contraseña'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              {loading ? (
                <button aria-label='login' className='focus:outline-none w-full px-2 py-2.5 my-2 bg-primary shadow-lg rounded-lg text-white' disabled>
                  <Spinner />
                </button>
              ) : (
                <button
                  aria-label='login'
                  className='focus:outline-none w-full px-2 py-2.5 my-2 bg-primary shadow-lg rounded-lg text-white'
                  type='submit'
                >
                  Iniciar Sesión
                </button>
              )}
              <div className='grid grid-cols-3 mt-3'>
                <div className='invisible'>...</div>
                <p className='col-span-2 text-right cursor-pointer text-primary font-futura-light' onClick={() => setIsRecoveringPassword(true)}>
                  ¿Olvidaste tu Contraseña?
                </p>
              </div>
            </form>
          ) : (
            <form onSubmit={handlePasswordRecovering}>
              <p className='text-center text-primary'>Enviar correo de recuperación</p>
              <div
                className={[
                  'relative flex w-full py-2 my-3 bg-white border rounded-xl ',
                  error?.campos?.includes('email') ? 'ring-1 ring-red-500 border-red-500' : 'border-primary',
                ].join(' ')}
              >
                <label htmlFor='email' className='self-center inline w-5 h-5 mx-2 text-primary'>
                  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11.11 13.23'>
                    <g id='Capa_2' data-name='Capa 2'>
                      <g id='Layer_1' data-name='Layer 1'>
                        <path fill='currentColor' d='M5.56,0A2.91,2.91,0,1,0,8.47,2.91,2.91,2.91,0,0,0,5.56,0Z' />
                        <path
                          fill='currentColor'
                          d='M5.56,6.35A5.55,5.55,0,0,0,0,11.91V13a.26.26,0,0,0,.26.26H10.85a.26.26,0,0,0,.26-.26V11.91A5.55,5.55,0,0,0,5.56,6.35Z'
                        />
                      </g>
                    </g>
                  </svg>
                </label>
                <input
                  id='email'
                  className='w-full outline-none'
                  type='text'
                  name='email'
                  placeholder='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <button
                aria-label='email'
                className='focus:outline-none w-full px-2 py-2.5 my-2 bg-primary shadow-lg rounded-lg text-white'
                type='submit'
              >
                Enviar
              </button>
              <div className='grid grid-cols-3 mt-3'>
                <p className='cursor-pointer text-primary font-futura-light' onClick={() => setIsRecoveringPassword(false)}>
                  Volver
                </p>
              </div>
            </form>
          )}
        </div>
      </div>
    </>
  );
};

export default withRouter(Login);
